.company {
  padding: 0 25px;
  margin-top: 70px;
}

.company > h1 {
  font-weight: 600;
  color: var(--topHeaderColor);
}
.cards_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}
.cards {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  margin-bottom: 80px;
}
.card {
  width: 350px;
  max-width: 350px;
  min-height: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.card > img {
  width: 100%;
  height: 50%;
  background-position: center;
  object-fit: cover;
}
.card > div {
  height: 50%;
  padding: 10px;
}
@media only screen and (max-width: 1880px) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 1500px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1118px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 737px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 350px) {
  .card {
    width: 300px;
    max-width: 350px;
  }
}
