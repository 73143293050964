.header {
  width: 100%;
  height: 115px;
  border-top: 7px solid var(--topHeaderColor);
  background-color: var(--headerColor);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  z-index: 1000;
}
.mobile-contact {
  display: none;
}
.header-contact-elements {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  top: -5px;
  width: 127px;
  height: 35px;
  font-family: "Rubik", sans-serif;
  background-color: var(--topHeaderColor);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.header-contact-elements > div {
  cursor: pointer;
}
.header-contact-elements > div > a {
  text-decoration: none;
  color: white;
}
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nav-logo {
  width: 200px;
  margin: 0 8vw;
}
.nav-menu {
  display: flex;
  flex-direction: row;
}
.nav-menu > .nav-link {
  margin: 0 40px;
  font-size: 20px;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.nav-menu > .nav-link:hover {
  color: #546e9c;
}

/* mobile navbar */

.hamburger {
  display: none;
}
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}
@media only screen and (max-width: 1209px) {
  .header {
    align-items: center;
    height: 50px;
    position: fixed;
    top: 0;
    z-index: 3;
  }
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 4rem;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }
  .nav-menu > .nav-link {
    color: var(--topHeaderColor);
    margin: 30px 0;
  }
  .mobile-contact {
    display: block;
  }
  .header-contact-elements {
    position: static;
    top: 0;
    width: 100%;
  }
  .nav-logo {
    width: 100px;
  }
  .header-contact {
    display: none;
  }
  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin: 0 20px;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
